import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import createTheme from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Ui from "@micado-digital/react-ui/components/Ui";
import { theme as customTheme } from "./themes/project";
import CSSProperties from "./utils/CSSProperties";

import "./css/index.css";

const Views = loadable(() => import("@Views"));

const theme = createTheme(customTheme);

const App = () => {
	useEffect(() => {
		CSSProperties(theme, alpha);

		const handleResize = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<Routes>
								<Route path="/*" element={<Views />} />
							</Routes>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
